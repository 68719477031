<template>
  <div id="chart-container-21641658"
       :class="containerClass"
  >
    <div id="chart-label-21641658"
         :class="labelClass">
      {{ label }}
    </div>
    <div id="chart-21641658">
      <vc-donut
        v-bind="donutProps"
        v-b-tooltip.hover.left="label"
      >
        <template #default>
          <div class="text-black-50"
               :style="'font-size: ' + innerLabelFontSize + '%;'"
          >
            {{ innerLabel }}
          </div>
        </template>
      </vc-donut>
    </div>
  </div>
</template>

<script>
export default {
  name: "RealTimeAssistantArcPie",
  data () {
    return {
      labelPlacementOrder: {
        top: -1,
        bottom: 1,
        left: -1,
        right: 1
      }
    }
  },
  props: {
    label: {
      type: String,
      required: false,
      default: "Talking share"
    },
    color: {
      type: String,
      required: false,
      default: "#2A2D52"
    },
    value: {
      type: Number,
      required: true
    },
    innerText: {
      type: String,
      required: false
    },
    total: {
      type: Number,
      required: false,
      default: 100 // think: value/total = ratio to display in arc pie
    },
    size: {
      type: Number,
      required: false,
      default: 60
    },
    labelPlacement: {
      type: String,
      required: false,
      default: "top"
    }
  },
  computed: {
    donutProps () {
      return {
        size: this.size,
        sections: [{ value: Math.min(this.value, this.total), color: this.color, label: this.label }],
        thickness: this.size / 3, // set this to 100 to render it as a pie chart instead
        hasLegend: false, // !this.answer,
        autoAdjustTextSize: false,
        legendPlacement: "top",
        total: this.total,
        foreground: "#0000000F"
      }
    },
    containerClass () {
      return "chart-container label-" + this.labelPlacement
    },
    labelClass () {
      return "m-2 font-size-12 order-" + this.labelPlacementOrder[this.labelPlacement]
    },
    innerLabelFontSize () {
      return this.size * 2
    },
    innerLabel () {
      return this.innerText ? this.innerText : this.value.toFixed(0) + "%"
    }
  }
}
</script>

<style scoped lang="scss">

.chart-container {
  display: flex;
  margin-bottom: 5px;
}

.label-top {
  flex-direction: column;
  text-align: center;
}

.label-bottom   {
  flex-direction: column;
  text-align: center;
}

.label-right {
  flex-direction: row;  /* default flex direction */
  align-items: center;
}

.label-left {
  flex-direction: row; /* default flex direction */
  align-items: center;
}
</style>
